@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selectedTypeExpense{
    background-color: #f99999 !important;
}
.selectedTypeRevenu{
    background-color: #8cdeb0 !important;
}
.selectedTypePonctual{
    background-color: #e2d347 !important;
}
.selectedTypeRegular{
    background-color: #83a6d4 !important;
}
.selectedTypeExpense, 
.selectedTypeRevenu, 
.selectedTypePonctual, 
.selectedTypeRegular{
    color: white !important;
}
.selectedTypeExpense:focus, 
.selectedTypeRevenu:focus, 
.selectedTypePonctual:focus, 
.selectedTypeRegular:focus, 
.MuiButton-outlined,
.MuiButton-text:focus,
button{
    outline: none !important;
}

.MuiPaper-rounded{
    width: 100%;
}
hr{
    border-top: 1px solid black;
    width: 80%;
}

.MuiBox-root{
    padding: 5px 0 !important;
}
.max-height{
    height: 100vh;
}

.MuiTab-textColorPrimary {
    color: rgba(0, 0, 0, 0.84) !important;
}
.selectedBtn, .Mui-selected{
    color: rgb(102, 31, 168) !important;
}
.chart-dimensions{
    height: 400px !important;
}
.center-flex{
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    gap: 10px;
}